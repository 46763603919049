<!--

  Link List Item

  A clickable item (button) in a list of links.  It accepts either a URL or a page as a destination.  When a URL is
  used, it will open in a new tab. 

  Example how to use:

    <link-list-item
      title="Personal information"
      :to="{ name: 'personal-information' }"
    ></link-list-item>

-->
<template>
	<BButton
		variant="link"
		class="d-flex"
		:href="href"
		:to="to"
		:target="target"
		:data-test-automation-id="getAutomationId()"
		:data-tracking-id="getAutomationId()"
	>
		<span class="mr-auto">{{ title }}</span>
		<font-awesome-icon :icon="['fal', 'chevron-right']" class="ml-auto"></font-awesome-icon>
	</BButton>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BButton } from 'bootstrap-vue';
export default {
	name: 'LinkListItem',
	components: {
		BButton
	},
	mixins: [IdMixin],

	props: {
		title: {
			type: String,
			required: true,
			description: 'The text to display on the link item.'
		},
		href: {
			type: String,
			required: false,
			default: null,
			description: 'A target URL.  Ex: https://www.medaviebc.ca'
		},
		to: {
			type: Object,
			required: false,
			default: null,
			description: "The target route of the link.  Ex: { name: 'member-card' }"
		}
	},

	computed: {
		/** If using <router-link> ('to' prop), open in same page.  If using URL ('href' prop), then open in a new tab.  */
		target() {
			return this.to ? '_self' : '_blank';
		}
	}
};
</script>

<style lang="scss" scoped>
.btn {
	text-align: left;
}

.btn.focus,
.btn:focus {
	box-shadow: none;
}
</style>
