<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<section id="actionCard" class="py-3">
		<p class="h5">Sample Action Cards</p>

		<BRow cols="2" cols-lg="4">
			<BCol>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 1.1
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 1.2
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 1.3
				</ActionCard>
			</BCol>
			<BCol>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 2.1
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 2.2
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 2.3
				</ActionCard>
			</BCol>
			<BCol>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 3.1
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 3.2
				</ActionCard>
			</BCol>
			<BCol>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 4.1
				</ActionCard>
				<ActionCard :icon="['fal', 'heartbeat']" route-name="claim-type">
					Sample Action Card 4.2
				</ActionCard>
			</BCol>
		</BRow>
	</section>
</template>

<script>
import ActionCard from '@/components/common/card/ActionCard.vue';
import { BRow, BCol } from 'bootstrap-vue';
export default {
	components: { ActionCard, BRow, BCol }
};
</script>
