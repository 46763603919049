<!-- 
  An Action Card is an achor formated as a rounded corner card with a glowing blue border
  on hover.  These are used to route a user to another part of the application.
	TODO: Add href support through scoped slot
-->
<template>
	<router-link
		:to="{ name: routeName }"
		class="card action-card"
		role="button"
		:aria-label="ariaLabel"
		:data-test-automation-id="getAutomationId('actionCard')"
		:data-tracking-id="getAutomationId('actionCard')"
	>
		<div class="card-body action-card-body">
			<card-icon :icon="icon"></card-icon>
			<div class="action-card-text">
				<slot></slot>
			</div>
		</div>
	</router-link>
</template>

<script>
import IdMixin from '@/mixins/id';
import CardIcon from '@/components/common/card/CardIcon.vue';
export default {
	components: { CardIcon },
	mixins: [IdMixin],
	props: {
		icon: {
			type: Array,
			required: true,
			description: "Font Awesome array notation.  Example: ['fal', 'heartbeat']"
		},
		routeName: {
			type: String,
			required: true,
			description: 'Name of destination route'
		}
	},

	computed: {
		/** Get the slot content and use it for the Aria label. */
		ariaLabel() {
			return this.$slots?.default[0]?.text;
		}
	}
};
</script>

<style lang="scss" scoped>
.action-card {
	text-decoration: none;
	text-align: center;
	margin-bottom: 0;

	&-body {
		box-shadow: 0px 0px 10px $gray-light;
		transition: 0.4s;
		font-family: $font-family-headline;
		color: $blue;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: -0.05px;

		&:hover {
			box-shadow: 0px 0px 10px $blue-light; // Glowing blue border
			color: $blue-dark;
		}
	}

	&-text {
		margin-top: 12px;
	}
}
</style>
