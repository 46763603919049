<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<section id="mesageBox" class="py-3">
		<div class="row mb-3">
			<div class="col-md-6 mb-3">
				<p class="h5">Sample Message Box</p>
				<b-button @click="claimWarning">Claim warning</b-button>
			</div>
		</div>
	</section>
</template>

<script>
import Vue from 'vue';
import { BVModalPlugin } from 'bootstrap-vue';
Vue.use(BVModalPlugin);

import { BButton } from 'bootstrap-vue';
export default {
	components: { BButton },
	methods: {
		async claimWarning() {
			const h = this.$createElement;

			const iconVNode = h('FontAwesomeIcon', {
				class: ['icon-warning'],
				props: { icon: ['fal', 'exclamation-triangle'] }
			});

			const titleVNode = h('span', [iconVNode, `${this.$t('title')}`]);

			const descriptionVNode = h('p', `${this.$t('message.description')}`);

			const confirmationVNode = h(
				'p',
				{ class: ['confirmation'] },
				`${this.$t('message.confirmation')}`
			);

			try {
				const value = await this.$bvModal.msgBoxConfirm([descriptionVNode, confirmationVNode], {
					title: [titleVNode],
					centered: true,
					okTitle: this.$t('button.leaveClaim'),
					cancelTitle: this.$t('button.stay'),
					okVariant: 'outline-secondary',
					cancelVariant: 'primary'
				});
				// eslint-disable-next-line no-console
				console.log('value: ', value);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log('error condition');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.icon-warning {
	display: block;
	font-size: 32px;
	margin-bottom: 20px;
	& ::v-deep path {
		color: $orange-dark;
	}
}

::v-deep .modal-header {
	margin: 30px 30px 20px 30px;
	padding: 0;
}

::v-deep .modal-body {
	margin-left: 30px;
	margin-right: 30px;
	margin-bottom: 0;
	padding: 0;
}

::v-deep .modal-footer {
	justify-content: flex-start;
	margin: 30px;
	padding: 0;
}

.confirmation {
	margin-bottom: 0;
	font-weight: 400;
}
</style>

<i18n>
{
  "en": {
    "title": "TODO: Wish to leave claim?",
    "message": {
      "description": "TODO: This is an explanation that member will loose claim data.",
      "confirmation": "TODO: Do you want to abandon your claim for now and update your email?"
    },
    "button": {
      "leaveClaim": "TODO: Leave claim",
      "stay": "TODO: Stay"
    }
  },
  "fr": {
    "title": "TODO FR: Wish to leave claim?",
    "message": {
      "description": "TODO FR: This is an explanation that member will loose claim data.",
      "confirmation": "TODO FR: Do you want to abandon your claim for now and update your email?"
    },
    "button": {
      "leaveClaim": "TODO FR: Leave claim",
      "stay": "TODO FR: Stay"
    }
  }
}
</i18n>
