<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<section>
		<div class="container">
			<div class="row">
				<div class="mt-2 col-md-6 d">
					<p class="h5">Default Accordion</p>
					<BaseAccordion>
						<BaseAccordionPanel
							id="baseSample"
							group-name="baseSample"
							button-label="click me to open/close accordion"
						>
							Content is now visible
						</BaseAccordionPanel>
					</BaseAccordion>
				</div>
				<div class="mt-2 col-md-6">
					<p class="h5">Accordion with buttons</p>
					<BaseAccordion>
						<BaseAccordionPanel
							id="sample"
							group-name="sample"
							button-label="click me to open/close accordion"
							:visible="accordionVisible"
							@input="changeVisibility"
						>
							Content Goes here
							<BaseButton
								label="close accordion"
								automation-id="btn-sample"
								@click="accordionVisible = false"
							/>
						</BaseAccordionPanel>
					</BaseAccordion>
				</div>
				<div class="mt-2 col-md-6">
					<p class="h5">Accordion Group</p>
					<BaseAccordion>
						<BaseAccordionPanel
							group-name="samplegroups"
							button-label="click me to open/close accordion group 1"
							border
						>
							<p>Group content one</p>
						</BaseAccordionPanel>
						<BaseAccordionPanel
							group-name="samplegroups"
							button-label="click me to open/close accordion group 2"
						>
							<p>Group content 2</p>
						</BaseAccordionPanel>
					</BaseAccordion>
				</div>
				<div class="mt-2 col-md-6">
					<p class="h5">Accordion Group individual</p>
					<BaseAccordion>
						<BaseAccordionPanel
							group-name="individualsample1"
							button-label="click me to open/close accordion group 1"
							border
						>
							Individual Group 1 content
						</BaseAccordionPanel>
						<BaseAccordionPanel
							group-name="individualsample2"
							button-label="click me to open/close accordion group 2"
						>
							Individual Group 2 content
						</BaseAccordionPanel>
					</BaseAccordion>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import BaseAccordion from '@/components/common/base/BaseAccordion';
import BaseAccordionPanel from '@/components/common/base/BaseAccordionPanel';
import IdMixin from '@/mixins/id';

@Component({
	name: 'SampleAccordion',
	mixins: [IdMixin],
	components: {
		BaseButton,
		BaseAccordion,
		BaseAccordionPanel
	}
})
export default class SampleAccordion extends Vue {
	baseAccordionVisible = false;
	accordionVisible = false;
	accordionGroup1 = false;
	accordionGroup2 = false;
	individualAccordionGroup1 = false;
	individualAccordionGroup2 = false;

	changeBaseAccordionVisibility(visible) {
		this.baseAccordionVisible = visible;
	}

	changeVisibility(visible) {
		this.accordionVisible = visible;
	}

	changeGroup1Visibility(visible) {
		this.accordionGroup1 = visible;
	}

	changeGroup2Visibility(visible) {
		this.accordionGroup2 = visible;
	}

	changeIndividualGroup1Visibility(visible) {
		this.individualAccordionGroup1 = visible;
	}

	changeIndividualGroup2Visibility(visible) {
		this.individualAccordionGroup2 = visible;
	}
}
</script>
