<!--
  A Font Awesome icon used in cards.
-->
<template>
	<FontAwesomeIcon v-if="icon" :class="iconClass" :icon="icon" aria-hidden="true"></FontAwesomeIcon>
</template>

<script>
export default {
	name: 'CardIcon',
	props: {
		icon: {
			type: Array,
			required: false,
			default() {
				return null;
			},
			description: "Font Awesome array notation.  Example: ['fal', 'heartbeat']"
		},
		iconClass: {
			type: [String, Object, Array],
			default: 'card-icon',
			required: false,
			description: 'CSS class (or classes) to apply to the icon'
		}
	}
};
</script>

<style lang="scss" scoped>
.card-icon {
	font-size: 32px;
	color: $gray;
}
</style>
