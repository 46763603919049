<!-- 
    This component allows the user to upload
    a file or multiple files
-->

<template>
	<ValidationProvider ref="provider" :rules="validationRules" slim>
		<BFormGroup label-for="fileuploadid">
			<BaseCard
				:automation-id="automationId"
				body-class="p-3"
				footer-class="p-3"
				:class="['upload-card', dragOverClass]"
			>
				<div
					class="dashed-border text-center p-3"
					@dragover.prevent="fileDragOver"
					@dragleave.prevent="fileDragLeave"
					@drop.stop.prevent="fileDrop"
				>
					<div v-if="isDragDisabled" class="invalid-overlay" />
					<FontAwesomeIcon
						v-if="isUploading"
						spin
						class="icon loading-icon"
						:icon="['fas', 'spinner']"
					></FontAwesomeIcon>
					<FontAwesomeIcon
						v-else
						color="#c6c6c6"
						class="icon"
						:icon="['fal', 'upload']"
						aria-hidden="true"
					/>
					<p class="josefin drag-drop-text my-3">{{ $t('dragDrop') }}<br />{{ $t('or') }}</p>
					<BaseButton
						type="file"
						automation-id="multi-file-upload-button"
						class="mb-3 text-break"
						:label="$t('addDocs')"
						@click="addDocsClick"
					/>
					<p class="position-relative">
						{{ $t('maxFiles', [maxFiles, formatBytes(combinedFileSizeLimit)]) }}
					</p>
					<p class="position-relative">{{ $t('fileTypes', [validFileTypesString]) }}</p>
					<BFormFile
						id="fileuploadid"
						ref="fileInput"
						v-model="file"
						:state="Boolean(files)"
						multiple
						:accept="`.${validFileTypes.join(',.')}`"
						class="file-input"
						@input="fileInput"
					/>
				</div>
				<BFormInvalidFeedback
					id="multiFileInvalidFeedback"
					:state="validationErrors.errors.length < 1"
					:force-show="false"
					class="mt-2 mb-n2 position-relative"
					aria-live="polite"
				>
					<span v-for="(error, key) in validationErrors.failedRules" :key="error">
						{{ key === 'required' ? errorMsgs[key] : $t('error.generic') }}
					</span>
				</BFormInvalidFeedback>
				<template v-if="files.length > 0" #footer>
					<BTable
						:items="files"
						:fields="[
							{ key: 'file_name', label: $t('table.fileName') },
							{ key: 'size', label: $t('table.size') },
							{ key: 'revise', label: '' }
						]"
						stacked="md"
						borderless
						cellspacing="0"
						class="multiple-file-table mb-0"
					>
						<template #cell(file_name)="data">
							<div class="josefin mt-4 mb-1 d-block d-md-none">
								{{ data.field.label }}
							</div>
							<div class="progress-container d-inline-flex w-100">
								<BProgress
									:max="100"
									height="20px"
									:class="['flex-grow-1', data.item.error ? 'progress-error' : '']"
								>
									<BProgressBar
										:aria-label="data.value"
										:value="
											!data.item.error && !data.item.loadedFromState
												? progressValue[data.value]
												: 100
										"
									>
										<div class="progress-text text-left ml-2">{{ data.value }}</div>
									</BProgressBar>
								</BProgress>
								<div class="progress-text align-self-center text-nowrap pl-2">
									<!-- eslint-disable -->
									{{
										progressValue[data.value] < 100
											? `${progressValue[data.value]}%`
											: !data.item.error
											? $t('table.complete')
											: $t('table.failed')
									}}
									<!-- eslint-enable -->
								</div>
							</div>
							<BFormInvalidFeedback
								v-if="data.item.error"
								id="singleFileFeedback"
								force-show
								aria-live="polite"
								class="mt-3 mt-md-2"
							>
								{{ fileErrorMsg(data.item.file_name) }}
							</BFormInvalidFeedback>
						</template>
						<template #cell(size)="data">
							<div class="d-flex align-items-baseline mt-2 mt-md-0">
								<span class="josefin d-block d-md-none mr-3">
									{{ data.field.label }}
								</span>
								<span class="text-nowrap">
									{{ formatBytes(data.value) }}
								</span>
							</div>
						</template>
						<template #cell(revise)="data">
							<div class="d-flex flex-wrap w-100 mt-2 mb-4 mt-md-0 mb-md-0">
								<BaseButton
									:class="[
										'p-0',
										'mr-4',
										'text-nowrap',
										data.item.error ? 'd-none d-md-block invisible' : 'visible'
									]"
									variant="link"
									:label="$t('view')"
									automation-id="btnViewFile"
									@click="viewFile(data.index)"
								/>
								<BaseButton
									class="p-0 text-nowrap"
									variant="link"
									:label="$t('remove')"
									automation-id="btnRemoveFile"
									@click="removeFile(data.index)"
								/>
							</div>
						</template>
						<template #custom-foot>
							<BTr class="multiple-file-table-footer-tr">
								<BTd class="docs-count" role="cell"
									>{{ files.length }} {{ $t('docsAttached', [files.length > 1 ? 's' : '']) }}
								</BTd>
								<BTd class="text-nowrap" role="cell">{{ formatBytes(filesTotalSize) }}</BTd>
								<BTd role="cell">
									<BaseButton
										class="multiple-file-footer-btn p-0"
										variant="link"
										:label="$t('removeAll')"
										automation-id="btnRemoveAll"
										@click="removeAllFiles"
									/>
								</BTd>
							</BTr>
						</template>
					</BTable>
					<div class="d-block d-md-none josefin mobile-table-footer">
						<div class="d-flex justify-content-between">
							<div>{{ files.length }} {{ $t('docsAttached', [files.length > 1 ? 's' : '']) }}</div>
							<div>{{ formatBytes(filesTotalSize) }}</div>
						</div>
						<BaseButton
							class="multiple-file-footer-btn mt-3 p-0"
							variant="link"
							:label="$t('removeAll')"
							automation-id="btnRemoveAll"
							@click="removeAllFiles"
						/>
					</div>
				</template>
				<template v-else #footer>
					<div class="josefin text-center">
						<p class="p-3 p-md-0">{{ $t('noDocs') }}</p>
					</div>
				</template>
			</BaseCard>
		</BFormGroup>
		<ErrorModal
			:has-close-button="false"
			:modal-show="showTooManyModal"
			:error-title="$t('error.tooManyTitle')"
			:error-message="$t('error.tooManyMessage', [maxFiles])"
		>
			<BaseButton
				automation-id="modalCloseBtn"
				variant="primary"
				class="mt-3 mb-n3"
				type="button"
				:label="$t('close')"
				@click="closeTooManyModal"
			/>
		</ErrorModal>
	</ValidationProvider>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id.js';
import {
	BFormGroup,
	BFormFile,
	BTable,
	BProgress,
	BProgressBar,
	BTr,
	BTd,
	BFormInvalidFeedback
} from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard';
import BaseButton from '@/components/common/base/BaseButton';
import { ValidationProvider, extend } from 'vee-validate';
import ErrorModal from '@/components/common/ErrorModal';
import Jimp from 'jimp';

extend('lessThanMaxFileSize', {
	validate(value, args) {
		let fileSize = 0;
		for (let i = 0; i < value.length; i++) {
			fileSize += value[i].size;
		}
		return fileSize <= args.combinedFileSizeLimit;
	},
	params: ['combinedFileSizeLimit']
});

extend('validFileType', {
	validate(value, args) {
		for (let i = 0; i < value.length; i++) {
			let fType = value[i].file_name || value[i].name;
			fType = fType.split('.');
			fType = fType[fType.length - 1].toLowerCase();
			if (!args.validFileTypes.includes(fType)) return false;
		}
		return true;
	},
	params: ['validFileTypes']
});

@Component({
	name: 'MultiFileUpload',
	components: {
		BFormGroup,
		BFormFile,
		BProgress,
		BProgressBar,
		BaseCard,
		BaseButton,
		BTable,
		BTr,
		BTd,
		ValidationProvider,
		BFormInvalidFeedback,
		ErrorModal
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: `multi-file-upload${Math.floor(Math.random() * 100)}`
		},
		claimType: {
			type: String,
			default: null
		},
		combinedFileSizeLimit: {
			type: Number,
			default: 26214400 // bytes (equals 25MB)
		},
		initialfiles: {
			type: Array,
			default: () => []
		},
		maxFiles: {
			type: Number,
			default: 10
		},
		required: {
			type: Boolean,
			default: false
		},
		validFileTypes: {
			type: Array,
			default: () => ['bmp', 'jpg', 'jpeg', 'pdf']
		}
	}
})
export default class MultiFileUpload extends Vue {
	validationErrors = {
		errors: []
	};
	validationRules = {
		required: this.required,
		lessThanMaxFileSize: this.combinedFileSizeLimit,
		validFileType: this.validFileTypes
	};
	file = [];
	files = [];
	progressValue = [];
	filesTotalSize = 0;
	filesTotalSizeWithErrors = 0;
	dragOverClass = '';

	showTooManyModal = false;

	get isUploading() {
		this.$emit('uploading', this.files.filter((file) => file.uploading === true).length > 0);
		return this.files.filter((file) => file.uploading === true).length > 0;
	}

	get validFileTypesString() {
		let fTypes = [...this.validFileTypes];
		for (let i = 0; i < fTypes.length; i++) {
			fTypes[i] = fTypes[i].toUpperCase();
		}
		let conjunction = this.$root.$i18n.locale === 'en' ? 'and ' : 'et';
		let fileTypesLength = fTypes.length;
		if (fileTypesLength < 2) return fTypes[0];
		if (fileTypesLength < 3) return fTypes.join(` ${conjunction} `);
		fTypes = fTypes.slice();
		fTypes[fileTypesLength - 1] = `${conjunction} ${fTypes[fileTypesLength - 1]}`;
		if (this.$root.$i18n.locale === 'fr') {
			fTypes = fTypes.join(', ');
			const lastComma = fTypes.lastIndexOf(',');
			const removedComma = fTypes.slice(0, lastComma) + fTypes.slice(lastComma + 1);
			return removedComma;
		}
		return fTypes.join(', ');
	}

	get isDragDisabled() {
		return this.files.length >= this.maxFiles || this.filesTotalSize >= this.combinedFileSizeLimit;
	}

	get errorMsgs() {
		return {
			required: this.$t('error.required'),
			lessThanMaxFileSize: this.$t('error.maxFileSize', [
				this.formatBytes(this.combinedFileSizeLimit)
			]),
			validFileType: this.$t('error.invalidFiletype', [this.validFileTypesString])
		};
	}

	mounted() {
		if (this.initialfiles.length > 0) {
			this.addFiles(this.initialfiles, false, true);
		}
	}

	addDocsClick() {
		this.$refs.fileInput.$el.childNodes[0].click();
	}

	isValidFileType(filename) {
		let fType = filename.split('.');
		fType = fType[fType.length - 1].toLowerCase();
		return this.validFileTypes.includes(fType);
	}

	fileErrorMsg(filename) {
		if (this.isValidFileType(filename)) {
			if (this.filesTotalSizeWithErrors > this.combinedFileSizeLimit && this.files.length > 1) {
				return this.$t('error.combinedMaxFileSize', [this.formatBytes(this.combinedFileSizeLimit)]);
			} else {
				return this.$t('error.maxFileSize', [this.formatBytes(this.combinedFileSizeLimit)]);
			}
		} else {
			return this.$t('error.invalidFiletype', [this.validFileTypesString]);
		}
	}

	async addFiles(files, showLoading = true, loadedFromState = false) {
		for (let i = 0; i < files.length; i++) {
			if (this.files.length === this.maxFiles) {
				this.showTooManyModal = true;
				break;
			}
			if (this.files.filter((file) => file.file_name === files[i].name).length === 0) {
				const fileError =
					this.filesTotalSize + files[i].size > this.combinedFileSizeLimit ||
					!this.isValidFileType(files[i].name);
				this.files.push({
					file_name: files[i].name,
					size: files[i].size,
					revise: '',
					error: fileError,
					loadedFromState,
					rawFile: files[i],
					uploading: showLoading ? true : false
				});
				if (!fileError) {
					this.$emit('uploading', showLoading ? true : false);
					let progress = showLoading ? 0 : 100;
					const size = parseFloat((files[i].size / (1000 * 1000)).toFixed(2));
					let speed;
					// simulating loading speed
					switch (true) {
						case size >= 20:
							speed = 3;
							break;
						case size >= 15:
							speed = 4;
							break;
						case size >= 10:
							speed = 5;
							break;
						case size >= 5:
							speed = 7;
							break;
						case size >= 1:
							speed = 10;
							break;
						default:
							speed = 50;
					}
					const interval = setInterval(() => {
						Vue.set(this.progressValue, files[i].name, progress);
						progress += speed;
						if (progress > 100) {
							setTimeout(() => {
								clearInterval(interval);
								Vue.set(this.progressValue, files[i].name, 100);
								if (this.files.find((file) => file.file_name === files[i].name)) {
									this.files.find((file) => file.file_name === files[i].name).uploading = false;
								}
							}, 500);
						}
					}, 100);
					this.filesTotalSize += files[i].size;
					this.filesTotalSizeWithErrors += files[i].size;
				} else {
					this.files[this.files.length - 1].error = true;
					this.files.find((file) => file.file_name === files[i].name).uploading = false;
					this.filesTotalSizeWithErrors += files[i].size;
					Vue.set(this.progressValue, this.progressValue.length - 1, 100);
				}
			}
		}
		this.validationErrors = await this.$refs.provider.validate(this.files);
		this.emitChange();
	}

	emitChange() {
		if (this.validationErrors.errors.length < 1) {
			const rawFiles = [];
			for (const file of this.files) {
				rawFiles.push(file.rawFile);
			}
			this.$emit('change', rawFiles);
		}
	}

	fileInput() {
		this.addFiles(this.file);
	}

	fileDragOver() {
		if (!this.isDragDisabled) {
			this.dragOverClass = 'drag-over-class';
		} else {
			this.dragOverClass = 'cursor-not-allowed';
		}
	}

	fileDragLeave() {
		this.dragOverClass = '';
	}

	fileDrop(e) {
		if (!this.isDragDisabled) {
			this.addFiles(e.dataTransfer.files);
		}
		this.dragOverClass = '';
	}

	async viewFile(index) {
		if (
			this.files[index].rawFile.type === 'image/tiff' ||
			this.files[index].rawFile.type === 'image/tif'
		) {
			// this converts tiff to jpg. This is needed to preview file in browser.
			const converted = await Jimp.read(URL.createObjectURL(this.files[index].rawFile));
			const dataUrl = await converted.getBase64Async(Jimp.MIME_JPEG);
			let newTab = window.open('');
			newTab.document.write(
				`<iframe width='100%' height='100%' frameBorder ='0' src='${encodeURI(dataUrl)}'></iframe>`
			);
		} else {
			window.open(URL.createObjectURL(this.files[index].rawFile), '_blank');
		}
	}

	removeFile(index) {
		const update = () => {
			this.progressValue[this.files[index].file_name] = 0;
			this.files.splice(index, 1);
			this.$refs['fileInput'].reset();
			this.$refs.provider.validate(this.files).then((res) => (this.validationErrors = res));
		};
		this.filesTotalSize -= this.files[index].size;
		update();
		const newFiles = [];
		for (const file of this.files) {
			newFiles.push(file.rawFile);
		}
		this.files = [];
		this.filesTotalSize = 0;
		this.$refs['fileInput'].reset();
		this.addFiles(newFiles, false);
		this.emitChange();
	}

	removeAllFiles() {
		this.filesTotalSize = 0;
		this.$refs['fileInput'].reset();
		this.files = [];
		this.progressValue = [];
		this.$refs.provider.validate(this.files).then((res) => (this.validationErrors = res));
		this.emitChange();
	}

	formatBytes(bytes) {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const sizes =
			this.$root.$i18n.locale === 'en'
				? ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))}\u00A0${sizes[i]}`;
	}

	closeTooManyModal() {
		this.showTooManyModal = false;
	}
}
</script>

<style lang="scss" scoped>
p {
	margin: initial;
}
.josefin {
	font-family: $josefin-font-family;
}
.icon {
	width: 24px;
	height: 24px;
	& path {
		color: #c6c6c6;
	}
}
.file-input {
	// need it this way instead of d-none for testers. They need it without !important
	display: none;
}
.upload-card {
	& ::v-deep .card-body {
		position: relative;
	}
	@include media-breakpoint-down(sm) {
		& ::v-deep .card-footer {
			padding: 0 !important;
		}
	}
}
.invalid-overlay {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.75);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.loading-icon {
	color: $blue;
}
.dashed-border {
	border-radius: 10px;
	outline: 1px dashed rgba($black, 0.8);
}
.drag-drop-text {
	font-size: 21px;
	line-height: 1.5rem;
}
.cursor-not-allowed {
	& > :first-child {
		cursor: not-allowed;
		pointer-events: none;
		background-color: $white;
	}
}
.drag-over-class {
	& > :first-child {
		background-color: $progress-blue;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		& > :first-child {
			outline: 2px dashed $blue-dark;
			& > svg {
				color: $blue-dark;
			}
			& > * {
				pointer-events: none;
			}
		}
	}
}
.multiple-file-table {
	border-collapse: separate;
	& .btn {
		font-family: $lato-font-family;
		font-weight: bold;
		font-size: 15px;
	}
	& ::v-deep td,
	::v-deep th {
		border: 1px solid $gray-light;
	}
	& ::v-deep thead th {
		background-color: $blue;
		color: $white;
		border-bottom: none;
		font-size: 16px;
		font-weight: 400;
		font-family: $josefin-font-family;
		@include media-breakpoint-only(md) {
			&:nth-child(2) {
				width: 100px;
			}
		}
		@include media-breakpoint-up(lg) {
			&:nth-child(2) {
				width: 150px;
			}
		}
		padding-right: 50px;
		white-space: nowrap;
		&:first-child {
			border-radius: 5px 0 0 0;
		}
		&:last-child {
			border-radius: 0 5px 0 0;
		}
		&:not(:first-child) {
			border-left: none;
		}
	}
	& ::v-deep tbody tr {
		background-color: $white;
		@include media-breakpoint-down(md) {
			& td > div {
				width: 100% !important;
				padding: 0 !important;
			}
			& td::before {
				content: '' !important;
				width: auto !important;
			}
		}
	}
	& ::v-deep td {
		font-size: 15px;
	}
	& ::v-deep tbody td {
		border-bottom: none;
		@include media-breakpoint-down(sm) {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
	& ::v-deep tfoot td {
		background-color: $dark-blue;
		&:first-child {
			border-radius: 0 0 0 5px;
		}
		&:last-child {
			border-radius: 0 0 5px 0;
		}
	}
	& ::v-deep tbody td:last-child,
	::v-deep thead th:last-child,
	::v-deep tfoot td:last-child {
		@include media-breakpoint-up(md) {
			width: 200px;
		}
	}
	& ::v-deep tbody td:not(:first-child),
	::v-deep tfoot td:not(:first-child) {
		border-left: none;
	}
	@include media-breakpoint-down(sm) {
		border-radius: 0;
		& ::before {
			font-family: $josefin-font-family !important;
			padding-top: 0.2rem !important;
		}
		& ::v-deep tr:not(:last-child) {
			border-bottom: 1px solid $gray-light;
		}
		& ::v-deep td {
			border: none;
		}
	}
}
.multiple-file-table-footer-tr {
	color: $white;
	& .docs-count {
		font-family: $josefin-font-family;
		font-size: 16px;
	}
}
.multiple-file-footer-btn,
.multiple-file-footer-btn:hover {
	color: $white !important;
}
.mobile-table-footer {
	padding: 15px;
	background-color: $blue-dark;
	color: $white;
}
@include media-breakpoint-down(sm) {
	.progress-container > div {
		width: auto !important;
	}
}
.progress {
	border: 1px solid $gray;
	background-color: $white;
	min-width: 1px;
	border-radius: 5px;
	@include media-breakpoint-up(md) {
		max-width: 300px;
	}
	@include media-breakpoint-only(md) {
		max-width: 200px;
	}
	& .progress-bar {
		background-color: $progress-blue;
		overflow: visible;
	}
}
.progress-error {
	background-color: $danger-background;
	border: 2px solid $error-red;
	& .progress-bar {
		background-color: $danger-background;
	}
}
.progress-text {
	color: $black;
	font-size: 12px;
	font-weight: 400;
}
.invalid-feedback {
	font-size: 14px;
	color: $error-red;
	font-weight: 400;
	background-color: $danger-background;
	border-color: $red;
	padding: 10px;
	border-radius: 5px;
	@include media-breakpoint-down(md) {
		width: 100% !important;
	}
}
</style>

<i18n>
{
	"en": {
		"noDocs": "0 documents attached",
		"docsAttached": "document{0} attached",
		"maxFiles": "You may include up to {0} attachments per claim, with a combined total size of {1}.",
		"fileTypes": "We support the following file types: {0}.",
		"dragDrop": "Drag and drop file",
		"or": "or",
		"removeAll": "Remove all",
		"addDocs": "Add documents",
		"view": "View",
		"remove": "Remove",
		"close": "Close",
		"table": {
			"fileName": "File name",
			"size": "Size",
			"complete": "Complete",
			"failed": "Failed"
		},
		"error": {
			"generic": "There are some issues with your upload. See below.",
			"required": "Add an attachment to continue.",
			"maxFileSize": "This document is over {0} and is too big to upload. Please remove this document and try again with a smaller document.",
			"combinedMaxFileSize": "The total size of all the files combined cannot be more than {0}. Please remove some files or upload smaller versions. ",
			"tooManyTitle": "Sorry!",
			"tooManyMessage": "You can attach up to {0} images with each claim. If you have more to include, please submit a second request.",
			"invalidFiletype": "This isn't a type of document we support. Please remove this document and upload a supported document type ({0})."
		}
	},
	"fr": {
		"noDocs": "0 document(s) joint(s)",
		"docsAttached": "document(s) joint(s)",
		"maxFiles": "Vous pouvez ajouter jusqu'à {0} pièces jointes par demande de règlement, mais la taille totale ne doit pas dépasser {1}.",
		"fileTypes": "Les types de documents suivants sont pris en charge : {0}.",
		"dragDrop": "Faites glisser le fichier",
		"or": "ou",
		"removeAll": "Supprimer tout",
		"addDocs": "Ajoutez des documents",
		"view": "Voir",
		"remove": "Supprimer",
		"close": "Fermer",
		"table": {
			"fileName": "Nom de fichier",
			"size": "Taille",
			"complete": "Complété",
			"failed": "Échec"
		},
		"error": {
			"generic": "Il y a des problèmes avec votre téléversement. Voir ci-dessous.",
			"required": "Veuillez joindre un document pour continuer.",
			"maxFileSize": "Ce document fait plus de {0} et est trop volumineux pour être téléversé. Veuillez le retirer et réessayer avec un document moins volumineux.",
			"combinedMaxFileSize": "La taille totale des fichiers combinés ne peut pas dépasser {0}. Veuillez retirer certains fichiers ou téléverser des versions moins volumineuses.",
			"tooManyTitle": "Désolé!",
			"tooManyMessage": "Vous pouvez joindre un maximum de {0} images avec chaque demande. Si vous devez en joindre davantage, veuillez soumettre une autre demande.",
			"invalidFiletype": "Nous n'acceptons pas ce type de document. Veuillez le retirer et téléverser un type de document accepté ({0})."
		}
	}
}
</i18n>
