<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<!--
	MssDemo

  This is a development view only.  It is meant to help developers showcase
  the components for documentation and for designers to review.
  -->

<template>
	<div class="mt-4">
		<BaseCard automation-id="1" title="Fuzzy Search Demo Page">
			<router-link :to="{ name: 'DemoFuzzySearch' }" role="button">
				Go to Fuzzy Search Demo Page >
			</router-link>
		</BaseCard>

		<BaseCard automation-id="1" title="Saving Search Results">
			<BCardText>
				Link to a claim history search page. It demonstrates saving search parameters and search
				results. From the search page, click the browser "back" button and then "forward" button to
				return to the search page. You will see the search paramaters and results are still
				displayed.
			</BCardText>

			<router-link :to="{ name: 'DemoHistory' }" role="button">
				Go to Search Demo Page >
			</router-link>
		</BaseCard>

		<!-- Included all props but left default values (just for display purposes) -->
		<MultiFileUpload :combined-file-size-limit="26214400" :max-files="10" required />
		<SampleActionCard></SampleActionCard>
		<SampleMessageBox></SampleMessageBox>

		<BaseCard automation-id="1" title="Base Card">
			<BCardText>Members will love this text that is presented using b-card-text</BCardText>
		</BaseCard>

		<BaseCard
			automation-id="1"
			title="Smaller Title"
			title-class="h4"
			sub-title="My subtitle"
			sub-title-class="h6"
		>
			<BCardText>Showing a card with a smaller title. Uses the titleClass prop.</BCardText>
		</BaseCard>

		<BaseCard automation-id="1" title="Base Display Group">
			<BaseDisplayGroup automation-id="1" label="Preferred language">English</BaseDisplayGroup>
		</BaseCard>

		<BaseCard automation-id="1" title="Mailing Address Display">
			<MailingAddressDisplay
				automation-id="1"
				address-line1="644 Main St."
				city="Moncton"
				province="NB"
				postal-code="E1A 8J9"
			></MailingAddressDisplay>
		</BaseCard>

		<SampleAlerts></SampleAlerts>
		<SampleAccordion></SampleAccordion>
		<SampleLinkList></SampleLinkList>
	</div>
</template>

<script>
import { BCardText } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import MailingAddressDisplay from '@/components/common/MailingAddressDisplay.vue';
import SampleAlerts from './SampleAlerts.vue';
import SampleAccordion from './SampleAccordion';
import SampleLinkList from './SampleLinkList.vue';
import MultiFileUpload from '@/components/common/MultiFileUpload';
import SampleMessageBox from './SampleMessageBox.vue';
import SampleActionCard from './SampleActionCard.vue';

export default {
	name: 'MssTheme',
	components: {
		BaseCard,
		BCardText,
		BaseDisplayGroup,
		MailingAddressDisplay,
		SampleAlerts,
		SampleAccordion,
		SampleLinkList,
		MultiFileUpload,
		SampleMessageBox,
		SampleActionCard
	}
};
</script>
