<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<section id="alerts" class="py-4">
		<div class="container">
			<!-- Basic alerts -->
			<div class="row mb-3">
				<div class="col-md mb-3">
					<p class="h5">Basic Alert with icon</p>
					<BCard>
						<BaseAlert variant="info" :icon="['fal', 'exclamation-circle']">
							Informational message. (info)
						</BaseAlert>
						<BaseAlert variant="primary" :icon="['fal', 'exclamation-circle']">
							Informational message. (primary)
						</BaseAlert>
						<BaseAlert variant="danger" :icon="['fal', 'exclamation-triangle']">
							Alert goes in here. (danger)
						</BaseAlert>
						<BaseAlert variant="warning" :icon="['fal', 'exclamation-circle']">
							Warning! Something is happening. And it's happening with a longer title. (warning)
						</BaseAlert>
						<BaseAlert variant="success" :icon="['fal', 'check-circle']">
							Success! Alert goes in here. (success)
						</BaseAlert>
					</BCard>
				</div>

				<div class="col-md mb-3">
					<p class="h5">Basic Alert</p>
					<BCard>
						<BaseAlert variant="info"> Informational message. (info) </BaseAlert>
						<BaseAlert variant="primary"> Informational message. (primary) </BaseAlert>
						<BaseAlert variant="danger"> Alert goes in here. (danger) </BaseAlert>
						<BaseAlert variant="warning">
							Warning! Something is happening. And it's happening with a longer title. (warning)
						</BaseAlert>
						<BaseAlert variant="success"> Success! Alert goes in here. (success) </BaseAlert>
					</BCard>
				</div>
			</div>

			<!-- Alternate alerts -->
			<div class="row mb-3">
				<div class="col-md mb-3">
					<p class="h5">Alternate Alert with icon</p>
					<BCard>
						<BaseAlert variant="info" alternate :icon="['fal', 'exclamation-circle']">
							Message:
							<template #description>
								This is a friendly informational message. (alternate-info)
							</template>
						</BaseAlert>
						<BaseAlert variant="primary" alternate :icon="['fal', 'exclamation-circle']">
							Message:
							<template #description>
								This is a friendly informational message. (alternate-primary)
							</template>
						</BaseAlert>
						<BaseAlert variant="danger" alternate :icon="['fal', 'exclamation-triangle']">
							Alert:
							<template #description>
								A message goes here. This is an example only. (alternate-danger)
							</template>
						</BaseAlert>
						<BaseAlert variant="warning" alternate :icon="['fal', 'exclamation-circle']">
							Reminder:
							<template #description>
								We can only accept Canadian bank accounts for direct deposit. (alternate-warning)
							</template>
						</BaseAlert>
						<BaseAlert variant="success" alternate :icon="['fal', 'check-circle']">
							Success!
							<template #description>
								Alert goes in here. This is an example only. (alternate-success)
							</template>
						</BaseAlert>
					</BCard>
				</div>

				<div class="col-md mb-3">
					<p class="h5">Alternate Alert</p>
					<BCard>
						<BaseAlert variant="info" alternate>
							Message:
							<template #description>
								This is a friendly informational message. (alternate-info)
							</template>
						</BaseAlert>
						<BaseAlert variant="primary" alternate>
							Message:
							<template #description>
								This is a friendly informational message. (alternate-primary)
							</template>
						</BaseAlert>
						<BaseAlert variant="danger" alternate>
							Alert:
							<template #description>
								A message goes here. This is an example only. (alternate-danger)
							</template>
						</BaseAlert>
						<BaseAlert variant="warning" alternate>
							Reminder:
							<template #description>
								We can only accept Canadian bank accounts for direct deposit. (alternate-warning)
							</template>
						</BaseAlert>
						<BaseAlert variant="success" alternate>
							Success!
							<template #description>
								Alert goes in here. This is an example only. (alternate-success)
							</template>
						</BaseAlert>
					</BCard>
				</div>
			</div>

			<!--  Additional content alerts -->
			<div class="row mb-3">
				<div class="col-md mb-3">
					<p class="h5">Additional Content Alert with icon</p>
					<BCard>
						<BaseAlert variant="info" :icon="['fal', 'exclamation-circle']">
							Informational message. (info)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
								<BLink>Important Link</BLink>
							</template>
						</BaseAlert>
						<BaseAlert variant="primary" :icon="['fal', 'exclamation-circle']">
							Informational message. (primary)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
								<BLink>Important Link</BLink>
							</template>
						</BaseAlert>
						<BaseAlert variant="danger" :icon="['fal', 'exclamation-triangle']">
							Alert goes in here. (danger)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
								<BLink>Important Link</BLink>
							</template>
						</BaseAlert>
						<BaseAlert variant="warning" :icon="['fal', 'exclamation-circle']">
							Warning! Something is happening. And it's happening with a longer title. (warning)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
								<BLink>Important Link</BLink>
							</template>
						</BaseAlert>
						<BaseAlert variant="success" :icon="['fal', 'check-circle']">
							Success! Alert goes in here. (success)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
								<BLink>Important Link</BLink>
							</template>
						</BaseAlert>
					</BCard>
				</div>

				<div class="col-md mb-3">
					<p class="h5">Additional Content Alert</p>
					<BCard>
						<BaseAlert variant="info">
							Informational message. (info)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
							</template>
						</BaseAlert>
						<BaseAlert variant="primary">
							Informational message. (primary)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
							</template>
						</BaseAlert>
						<BaseAlert variant="danger">
							Alert goes in here. (danger)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
							</template>
						</BaseAlert>
						<BaseAlert variant="warning">
							Warning! Something is happening. And it's happening with a longer title. (warning)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
							</template>
						</BaseAlert>
						<BaseAlert variant="success">
							Success! Alert goes in here. (success)
							<template #additional-content>
								Lorem ipsum dolor sit amet, linked text consectetur adipiscing elit. Fusce interdum
								tempor venenatis. Integer a tristique tellus. Proin porta nec odio vitae dictum.
								Maecenas id purus bibendum, lacinia odio in, condimentum est. Sed vehicula egestas
								ligula nec aliquam. Sed leo erat, aliquet a sodales maximus, ornare aliquam tortor.
							</template>
						</BaseAlert>
					</BCard>
				</div>
			</div>

			<!-- Full width example -->
			<div class="row mb-3">
				<div class="col-md-6 mb-3">
					<p class="h5">Full Width Alert</p>
					<BCard>
						<BaseAlert
							class="alert-full-width"
							variant="warning"
							alternate
							:icon="['fal', 'exclamation-circle']"
						>
							Reminder:
							<template #description>
								We can only accept Canadian bank accounts for direct deposit. (alternate-warning)
							</template>
						</BaseAlert>
						<p>
							Some designs may call for a full width alert. In other words, we want the alert to
							ignore the container's padding and stretch out to the parent's border.
						</p>
						<p>To make this happen, add a bit of css to make negative margins on the alert.</p>
						<p class="h5">Example</p>
						<pre>
              <code>
  .alert-full-width {
    // Use negative margins to overlap the card padding.
    margin-left: -15px;
    margin-right: -15px;
    @include media-breakpoint-up(md) {
      // There is more padding on cards on larger screens.
      margin-left: -30px;
      margin-right: -30px;
    }
  }
						  </code>
            </pre>
					</BCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import { BCard, BLink } from 'bootstrap-vue';
export default {
	components: {
		BCard,
		BLink,
		BaseAlert
	}
};
</script>

<style lang="scss" scoped>
.alert-full-width {
	margin-left: -30px;
	margin-right: -30px;
}
</style>
