<!--

  Link List

  A container for a list of links.  Provides a header (if required) and a slot for a list of links.  The list of 
  links is wrapped by the <nav> tag to help with accessibility.

  Example how to use:

    <link-list
      header-title="Account Information"
      header-text="Keep your address and contact information up to date."
    >
      <link-list-item
        title="Personal information"
        :to="{ name: 'personal-information' }"
      ></link-list-item>
      <link-list-item
        title="Medavie web site"
        href="https://www.medaviebc.ca"
      ></link-list-item>
    </link-list>

-->

<template>
	<BCard :automation-id="getAutomationId()">
		<template v-if="showHeader" #header>
			<h2 class="h3" :class="{ 'title-only': !headerText }">{{ headerTitle }}</h2>
			{{ headerText }}
		</template>
		<nav>
			<BButtonGroup vertical>
				<slot></slot>
			</BButtonGroup>
		</nav>
	</BCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BButtonGroup, BCard } from 'bootstrap-vue';
export default {
	components: {
		BButtonGroup,
		BCard
	},
	mixins: [IdMixin],

	props: {
		headerTitle: {
			type: String,
			required: false,
			default: null
		},
		headerText: {
			type: String,
			required: false,
			default: null
		}
	},

	computed: {
		/** If either the header title or the header text is available, display the link list (card) header. */
		showHeader() {
			return this.headerTitle || this.headerText;
		}
	}
};
</script>

<style lang="scss" scoped>
.btn-group-vertical {
	width: 100%;
}

.card {
	margin-bottom: 15px;
}

.card-header {
	padding: 30px 15px 20px 15px;
	background-color: $white;

	@include media-breakpoint-up(md) {
		padding: 30px; // More padding on larger screens.
	}
}

.card-body {
	padding: 0;
}

.btn-link {
	padding: 20px 15px;
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	border-color: $gray-light;

	@include media-breakpoint-up(md) {
		padding: 20px 30px;
	}
}

.btn-link:last-child {
	border-bottom: 0;
}

.title-only {
	// When there is only a title (no extra text) we remove the margin from the bottom of the title <p> tag
	margin-bottom: 0;
}
</style>
