<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<section id="linkList" class="py-3">
		<div class="container">
			<div class="row mb-3">
				<div class="col-md-6 mb-3">
					<p class="h5">Sample Link List</p>
					<LinkList
						header-title="Account Information"
						header-text="Keep your address and contact information up to date to make sure you never miss important updates."
					>
						<LinkListItem
							title="Personal information"
							:to="{ name: 'personal-information' }"
						></LinkListItem>
						<LinkListItem
							title="People on your plan"
							:to="{ name: 'people-on-your-plan' }"
						></LinkListItem>
						<LinkListItem title="Member card" :to="{ name: 'member-card' }"></LinkListItem>
						<LinkListItem title="Medavie web site" href="https://www.medaviebc.ca"></LinkListItem>
					</LinkList>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import LinkList from '@/components/common/LinkList.vue';
import LinkListItem from '@/components/common/LinkListItem.vue';
export default {
	components: {
		LinkList,
		LinkListItem
	}
};
</script>
